


import React from "react";
import shopping from '../assets/images/Wavy_Bus-17_Single-09.jpg'
import { Col, Container, Row } from "react-bootstrap";

const authLayout = (ChildComponent) => {
    const AuthLayout = (props) => {
        return (
            <>
                {/* <section className="vh-100">
                    <div className="container-fluid h-custom">
                        <div className="row ">
                            <div className="col-md-9 col-lg-6 col-xl-5">
                                <img
                                    alt="hey"
                                    src={shopping}
                                    className="img-fluid"
                                />
                            </div>
                            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                                <ChildComponent {...props} />
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="loginbg">
                    <Container fluid>
                        <Row className="align-items-center justify-content-center " style={{ height: "100vh" }}>
                            <Col md="8">
                                <Row className="align-items-center shadow bg-white m-3" style={{ borderRadius: "30px", overflow: "hidden" }}>
                                    <Col md='6' className="loginImg">
                                        <img
                                            alt="hey"
                                            src={shopping}
                                            className="img-fluid"
                                            style={{ height: "500px" }}
                                        />
                                    </Col>
                                    <Col md='4'>
                                        <div className="">

                                            <ChildComponent {...props} />
                                        </div>
                                    </Col>
                                </Row>



                            </Col>

                        </Row>

                    </Container>
                </section >
            </>
        );
    };

    return AuthLayout;
};

export default authLayout;
