import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import { Link } from "react-router-dom";
import { BaseUrl, token } from "../../utils/Token";
import { AddIcCallOutlined, PlusOne } from "@mui/icons-material";

// it is category List And this View Product

function UserList(props) {
  const [user, setUser] = useState([]);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/fetch_users_admin?type=${props.type}`,
        {
          headers,
        }
      );
      setUser(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {

  };

  const columns = [

    { field: "phone", headerName: "Phone", width: 300 },

    {
      field: "image",
      headerName: "image",
      width: 400,
      renderCell: (params) => {
        const { value } = params;
        return (
          <img
            src={`http://62.72.30.137:8000/${value}`}
            className="img-fluid tableimgmui"
          ></img>
        );
      },
    },


    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link to={`/userDetail/${id}`}>
            <IconButton
              style={{ color: "black" }}
              aria-label="edit"
              onClick={() => handleEditClick(id)}
            >
              <EditIcon />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  // const reversedRows = [...user].reverse();
  return (
    <>
      <section>
        <Container>
          <Row>

            {props.type == "Vendor" && <>
              <div className="col-md-12">
                <div className="text-end mb-3">
                  <Link to={'/addvendor'} className="btn bg-primary text-white">Add Vendor</Link>
                </div>
              </div></>}
            <Col md="12">
              <div className="bg-white  box">
                <h4 className="px-3 py-3">{props.type} List</h4>
                <div className="">
                  <DataGrid
                    rows={user}
                    columns={columns}
                    getRowId={getRowId}
                    initialState={{
                      ...user.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(UserList);
