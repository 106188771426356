import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { token } from "../../utils/Token";
import { toast } from "react-toastify";

function EditCategory() {
  const [category, setCategory] = useState([]);
  const [name, setName] = useState("");
  const [productthis, setProductThis] = useState("");
  const [flow, setFlow] = useState("");
  const [file, setFile] = useState(null);
  const [flowApi, setFlowApi] = useState([]);
  const [parent_category, setParent_Category] = useState("");
  const { id } = useParams();

  const [open, setOpen] = useState(false);

  const naivgate = useNavigate();

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  const getCategorybyId = () => {
    axios
      .get(`http://62.72.30.137:8000/single_category/${id}`, { headers })
      .then((res) => {
        setCategory(res.data.data);
        setParent_Category(res.data.data.parent_category._id);
        setProductThis(res.data.data.show_product_after_this);

        setFlow(res.data.data.flow._id);
        setName(res.data.data.name);

        setFile(res.data.data.image);
        console.log(file);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const [categorylist, setcategorylist] = useState([]);
  const getCategoryList = () => {
    axios.get("http://62.72.30.137:8000/category", { headers }).then((res) => {
      setcategorylist(res.data.data);
    });
    console.log(categorylist);
  };
  const FlowgetApi = () => {
    axios
      .get("http://62.72.30.137:8000/flows", { headers })
      .then((res) => {
        setFlowApi(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCategorybyId();
    FlowgetApi();
    getCategoryList();
  }, [id]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("show_product_after_this", productthis);
    formData.append("flow", flow);
    formData.append("parent_category", parent_category);
    formData.append("_id", id);
    formData.append("file", file); // Add the file to the form data

    axios
      .put(`http://62.72.30.137:8000/category`, formData, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.status == "OK") {
          toast.success("Data updated  Successfully");
          setOpen(false);
          naivgate("/categorylist");
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        toast.error("Data not Updated");
        setOpen(false);
      });
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile); // Store the uploaded file directly
  };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white box">
                <h4 className="px-3 py-3">Update Category</h4>
                <div className="">
                  <Form onSubmit={handleFormSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            required
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Flow
                          </label>
                          <select
                            className="form-select"
                            name="flow"
                            aria-label="Default select example"
                            value={flow}
                            onChange={(e) => setFlow(e.target.value)}
                          >
                            <option selected>Select a Flow</option>
                            {flowApi?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Parent Category
                          </label>

                          <select
                            className="form-select"
                            name="parent_category"
                            aria-label="Default select example"
                            value={parent_category}
                            onChange={(e) => setParent_Category(e.target.value)}
                          >
                            <option selected>Select a Parent Category</option>
                            {categorylist?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            show_product_after_this
                          </label>
                          <select
                            className="form-select"
                            name="show_product_after_this"
                            aria-label="Default select example"
                            value={productthis}
                            onChange={(e) => setProductThis(e.target.value)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Image
                          </label>
                          <input
                            type="file"
                            name="image"
                            // onChange={(e) => setFile(e.target.value)}
                            onChange={handleFileUpload}
                            className="form-control"
                          />
                        </FormGroup>
                        <div className="mt-3">
                          {file && (
                            <p>
                              Uploaded file:{" "}
                              <span className="fw-bold">{file.name}</span>
                            </p>
                          )}
                        </div>
                      </Col>

                      <Col md="12">
                        <div className="">
                          <button className="btn bg-primary text-white">
                            Update Category
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(EditCategory);
