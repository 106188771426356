import React, { useState } from "react";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";

function NewPRoduct() {
  const [fileName, setFileName] = useState("");

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFileName(uploadedFile.name);
  };
  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  addProduct">
                <h4 className="px-3 py-3">New Product</h4>
                <div className="">
                  <Form >
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Name
                          </label>
                          <input type="text" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Description
                          </label>
                          <input type="text" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Category
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Open this select menu</option>
                            <option value="1">Books</option>
                            <option value="2">Clothes</option>
                            <option value="3">Electronic</option>
                            <option value="4">Toys</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Product Code
                          </label>
                          <input type="text" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            SKU
                          </label>
                          <input type="text" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Price
                          </label>
                          <input type="number" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Sale Price
                          </label>
                          <input type="number" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Quantity
                          </label>
                          <input type="number" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Drop Product Image
                          </label>
                          <div className="">
                            <label
                              htmlFor="uploadProduct"
                              style={{ cursor: "pointer" }}
                              className="fw-bold productlable"
                            >
                              <i className="fa-solid fa-cloud-arrow-up text-secondary fs-3"></i>
                            </label>
                            <input
                              type="file"
                              id="uploadProduct"
                              className="d-none"
                              onChange={handleFileUpload}
                            />
                          </div>
                        </FormGroup>
                        {fileName && (
                          <p>
                            Uploaded file:{" "}
                            <span className="fw-bold">{fileName}</span>
                          </p>
                        )}
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Minimum Order Amount
                          </label>
                          <input type="number" className="form-control" />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <div className="">
                          <button className="btn">Add Product</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(NewPRoduct);
