import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import { Link } from "react-router-dom";
import { token } from "../../utils/Token";

function FlowList() {
  const [flow, setFlow] = useState([]);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("http://62.72.30.137:8000/flows", {
        headers,
      });
      setFlow(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {
    
  };

  const columns = [
    // { field: "_id", headerName: "ID", width: 400 },
    { field: "name", headerName: "Name", width: 400 },
    // {
    //   field: "image",
    //   headerName: "image",
    //   width: 250,
    // },

    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link to={`/single_flow/${id}`}>
            <IconButton
              style={{ color: "black" }}
              aria-label="edit"
              onClick={() => handleEditClick(id)}
            >
              <EditIcon />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  box">
                <h4 className="px-3 py-3">Flow List</h4>
                <div className="">
                  <DataGrid
                    rows={flow}
                    columns={columns}
                    getRowId={getRowId}
                    initialState={{
                      ...flow.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  // checkboxSelection
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(FlowList);
