import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoutes = () => {
    // Check if the token is present in local storage
    const token = localStorage.getItem('token');

    return token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
