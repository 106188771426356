import React from "react";

function FlowComponent(props) {
  return (
    <>
      <input
        type="text"
        name="name"
        className="form-control"
        required
        value={props.value}
        onChange={props.onChange}
      />
    </>
  );
}

export default FlowComponent;
