import React, { useEffect, useState } from "react";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import axios from "axios";
import { BaseUrl, token } from "../../utils/Token";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

function AddPromo() {
  const [code, setCode] = useState("");
  const { id } = useParams();

  const [off_type, setoff_type] = useState("");
  const [file, setFile] = useState(null);

  const [max_off, setmax_off] = useState();
  const [offafterValue, setoffAftervalue] = useState("");
  const [frequency, setFrequency] = useState("");
  const [startDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [valuebox, setValuebox] = useState("");
  const [description, setdescription] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  //   const getPromobyId = () => {
  //     axios.get(`${BaseUrl}/promos?_id=${id}`).then((res) => {
  //       console.log(res.data.data);
  //     });
  //   };

  const location = useLocation();

  const editpromo = location.pathname === `/editpromo/${id}`;

  const getPromobyId = (e) => {
    axios.get(`${BaseUrl}/promos?_id=${id}`, { headers }).then((res) => {
      // setProductApi(res.data.data);
      console.log(res.data.data);
      setCode(res.data.data.code);
      setStartDate(
        res.data.data.start_date
          ? res.data.data.start_date.substring(0, 10)
          : ""
      );
      setEndDate(
        res.data.data.end_date ? res.data.data.end_date.substring(0, 10) : ""
      );
      setoff_type(res.data.data.off_type);
      setmax_off(res.data.data.max_off);
      setoffAftervalue(res.data.data.off_after_this_value);
      setFrequency(res.data.data.frequency);
      setValuebox(res.data.data.value);
      setdescription(res.data.data.description);
    });
    // console.log(product);
    // onsole.log(category[3].parent_category.name);
  };

  useEffect(() => {
    if (editpromo) {
      getPromobyId();
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);

    const formData = new FormData();
    formData.append("code", code);
    formData.append("off_type", off_type);
    formData.append("max_off", max_off);

    formData.append("off_after_this_value", offafterValue);

    formData.append("file", file); // Add the file to the form data
    formData.append("frequency", frequency);
    formData.append("start_date", startDate);
    formData.append("end_date", EndDate);
    formData.append("description", description);
    formData.append("value", valuebox);

    if (id) {
      axios
        .put(`${BaseUrl}/promos`, formData, { headers })
        .then((response) => {
          // Handle successful response
          console.log(response.data);
          if (response.data.status == "OK") {
            setOpen(false);
            toast.success("data Submitted Succesfully");
            // navigate("/promolist");
          }
        })
        .catch((error) => {
          // Handle error
          console.error(error);
          setOpen(false);
          toast.error("data Not Submitted");
        });
    } else {
      axios
        .post(`${BaseUrl}/promos`, formData, { headers })
        .then((response) => {
          // Handle successful response
          console.log(response.data);
          if (response.data.status == "OK") {
            setOpen(false);
            toast.success("data Submitted Succesfully");
            // navigate("/promolist");
          }
        })
        .catch((error) => {
          // Handle error
          console.error(error);
          setOpen(false);
          toast.error("data Not Submitted");
        });
    }
  };

  //   const FlowgetApi = () => {
  //     axios.get("http://62.72.30.137:8000/flows", { headers }).then((res) => {
  //       setFlowApi(res.data.data);
  //     });
  //     console.log(flowApi);
  //     // console.log(Brand[3].parent_Brand.name);
  //   };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white box">
                {editpromo ? (
                  <>
                    <h4 className="px-3 py-3"> Edit Promos</h4>
                  </>
                ) : (
                  <>
                    <h4 className="px-3 py-3"> Add Promos</h4>
                  </>
                )}
                <div className="">
                  <Form onSubmit={handleSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Code <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            name="code"
                            className="form-control"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Start Date <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            required
                            name="start_date"
                            className="form-control"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            End Date <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            required
                            name="end_date"
                            className="form-control"
                            value={EndDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Off Type
                          </label>
                          <input
                            type="text"
                            required
                            name="off_type"
                            className="form-control"
                            value={off_type}
                            onChange={(e) => setoff_type(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Image
                          </label>
                          <input
                            type="file"
                            name="file"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Maximum Off
                          </label>
                          <input
                            type="text"
                            required
                            name="max_off"
                            className="form-control"
                            value={max_off}
                            onChange={(e) => setmax_off(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            off_after_this_value
                          </label>
                          <input
                            type="text"
                            required
                            name="off_after_this_value"
                            className="form-control"
                            value={offafterValue}
                            onChange={(e) => setoffAftervalue(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            frequency
                          </label>
                          <input
                            type="text"
                            required
                            name="frequency"
                            className="form-control"
                            value={frequency}
                            onChange={(e) => setFrequency(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Value
                          </label>
                          <input
                            type="text"
                            required
                            name="value"
                            className="form-control"
                            value={valuebox}
                            onChange={(e) => setValuebox(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Description
                          </label>
                          <textarea
                            name="description"
                            className="form-control"
                            id=""
                            value={description}
                            onChange={(e) => setdescription(e.target.value)}
                            rows="5"
                          ></textarea>
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <div className="">
                          {editpromo ? (
                            <>
                              <button className="btn bg-primary text-white">
                                Edit Promo
                              </button>
                            </>
                          ) : (
                            <>
                              <button className="btn bg-primary text-white">
                                Add Promo
                              </button>
                            </>
                          )}
                          {/* <button className="btn bg-primary text-white">
                            Add Promo
                          </button> */}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Loader open={open} />
    </>
  );
}

export default adminLayout(AddPromo);
