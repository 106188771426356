import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { token } from "../../utils/Token";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

function EditBrand() {
  const [brand, setBrand] = useState([]);
  const [name, setName] = useState("");

  const [flow, setFlow] = useState("");
  const [file, setFile] = useState(null);
  const [flowApi, setFlowApi] = useState([]);
  const [open, setOpen] = useState(false);

  const { id } = useParams();
  const naivgate = useNavigate();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  const getBrandbyId = () => {
    axios
      .get(`http://62.72.30.137:8000/single_brand/${id}`, { headers })
      .then((res) => {
        setBrand(res.data.data[0]);
        console.log(brand);

        setFlow(res.data.data[0].flow._id);
        setName(res.data.data[0].name);

        setFile(res.data.data[0].image);
        console.log(file);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const FlowgetApi = () => {
    axios
      .get("http://62.72.30.137:8000/flows", { headers })
      .then((res) => {
        setFlowApi(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBrandbyId();
    FlowgetApi();
  }, [id]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    const formData = new FormData();
    formData.append("name", name);

    formData.append("flow", flow);

    formData.append("_id", id);
    formData.append("file", file); // Add the file to the form data

    axios
      .put(`http://62.72.30.137:8000/brands`, formData, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.status == "OK") {
          toast.success("Data Updated Successfully");
          setOpen(false);
          naivgate("/brandlist");
        }
        // You can perform any additional actions after the update is successful
      })
      .catch((error) => {
        console.log("Error updating data:", error);
        toast.error("Data Not Updated Submited ");
        setOpen(false);
        naivgate("/brandlist");

        // Handle any errors that occurred during the update
      });
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile); // Store the uploaded file directly
  };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white box">
                <h4 className="px-3 py-3">Update Brand</h4>
                <div className="">
                  <Form onSubmit={handleFormSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            required
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Flow
                          </label>
                          <select
                            className="form-select"
                            name="flow"
                            aria-label="Default select example"
                            value={flow}
                            onChange={(e) => setFlow(e.target.value)}
                          >
                            <option selected>Selected a Flow</option>
                            {flowApi?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Image
                          </label>
                          <input
                            type="file"
                            name="image"
                            // onChange={(e) => setFile(e.target.value)}
                            onChange={handleFileUpload}
                            className="form-control"
                          />
                        </FormGroup>
                        <div className="mt-3">
                          {file && (
                            <p>
                              Uploaded file:{" "}
                              <span className="fw-bold">{file.name}</span>
                            </p>
                          )}
                        </div>
                      </Col>

                      <Col md="12">
                        <div className="">
                          <button className="btn bg-primary text-white">
                            Update Brand
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Loader open={open} />
    </>
  );
}

export default adminLayout(EditBrand);
