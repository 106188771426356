import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
import { BaseUrl, token } from "../utils/Token";
import adminLayout from "../hoc/adminLayout";

function OrderList() {
  const [user, setUser] = useState([]);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(`${BaseUrl}/order_list`, {
  //       headers,
  //     });
  //     setUser(response.data.data);
  //   } catch (error) {
  //     console.error("Error fetching category data:", error);
  //   }
  // };

  function removeBackslashes(inputString) {
    // Check if inputString is a string, if not, return it as is
    if (typeof inputString !== 'string') {
      return inputString;
    }
    return inputString.replace(/\\/g, '');
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/invoice_list`, {
        headers,
      });
      setUser(response.data.data);


    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {

  };

  // const columns = [
  //   // { field: "_id", headerName: "ID", width: 300 },
  //   { field: "top_level_order_status", headerName: "Status", width: 350 },
  //   { field: "payable", headerName: "Payable", width: 350 },
  //   // {
  //   //   field: "image",
  //   //   headerName: "image",
  //   //   width: 250,
  //   // },
  //   {
  //     field: "payment_status",
  //     headerName: "payment_status",
  //     width: 300,
  //     // renderCell: (params) => {
  //     //   const { value } = params;
  //     //   return (
  //     //     <img
  //     //       src={`http://62.72.30.137:8000/${value}`}
  //     //       className="img-fluid tableimgmui"
  //     //     ></img>
  //     //   );
  //     // },
  //   },
  //   // {
  //   //   field: "edit",
  //   //   headerName: "Edit",
  //   //   width: 100,
  //   //   renderCell: (params) => {
  //   //     const id = params.row._id;
  //   //     return (
  //   //       <Link to={`/product_single/${id}`}>
  //   //         <IconButton
  //   //           style={{ color: "black" }}
  //   //           aria-label="edit"
  //   //           onClick={() => handleEditClick(id)}
  //   //         >
  //   //           <EditIcon />
  //   //         </IconButton>
  //   //       </Link>
  //   //     );
  //   //   },
  //   // },
  // ];



  const columns = [

    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => {

        let saledata = removeBackslashes(params.row.sale);
        saledata = JSON.parse(saledata)
        let image = saledata[0].product_variant.product.image


        return <div>
          <img src={`${BaseUrl}/${image}`} alt="" style={{ height: "50px", width: "50px", borderRadius: "10px", objectFit: "contain" }} />
        </div>;
      }
    },

    {
      field: "short_name",
      headerName: "Short Name",
      flex: 1,
      renderCell: (params) => {

        let saledata = removeBackslashes(params.row.sale);
        saledata = JSON.parse(saledata)
        let name = saledata[0].product_variant.product.short_name


        return <div>
          {name}
        </div>;
      }
    },

    {
      field: "price_range",
      headerName: "Price Range",
      flex: 1,
      renderCell: (params) => {

        let saledata = removeBackslashes(params.row.sale);
        saledata = JSON.parse(saledata)
        let name = saledata[0].product_variant.product.price_range


        return <div>
          {name}
        </div>;
      }
    },





  ];

  const reversedRows = [...user].reverse();

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  box">
                <h4 className="px-3 py-3">Invoice List</h4>
                <div className="">
                  <DataGrid
                    rows={reversedRows}
                    columns={columns}
                    getRowId={getRowId}
                    initialState={{
                      ...user.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  // checkboxSelection
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(OrderList);
