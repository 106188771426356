

import React from "react";
import UserList from "./UserList";

function Influencer() {
  return <UserList type="Influencer" />;
}

export default Influencer;
