import { useState } from "react";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function EditModal(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-dark"> {props.title}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Form onSubmit={props.onSubmit}>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label for="exampleEmail">Image</label>
                    <input
                      type="file"
                      name="image"
                      // onChange={(e) => setFile(e.target.value)}
                      onChange={props.onChange}
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <button className="btn bg-primary text-white mt-3">
                  Update
                </button>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditModal;
