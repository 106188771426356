

import React, { useState } from "react";

const Header = (props) => {
    // const [isClicked, setIsClicked] = useState(false);

    // const handleClick = () => {
    //     setIsClicked(!isClicked);
    // };
    return (
        <nav className={props.className} >
            <div className="container-fluid">
                <button
                    className={`navbar-toggler mobilenav `}
                    type="button"
                    onClick={props.handleClick}
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse p-2" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mt-2 mt-lg-0">

                        {/* <li className="nav-item dropdown notifications">
                            <a
                                className="nav-link dropdown-toggle"
                                id="navbarDropdown"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i className="fa fa-bell"></i>
                            </a>
                            <div
                                className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="navbarDropdown"
                            >
                                <a className="dropdown-item" href="#!">
                                    Action
                                </a>
                                <a className="dropdown-item" href="#!">
                                    Another action
                                </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#!">
                                    Something else here
                                </a>
                            </div>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>

    );
};

export default Header;
