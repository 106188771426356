import React from "react";
import { Col, FormGroup } from "react-bootstrap";

function AddRowComponent(props) {
  return (
    <Col md="12">
      <div className="">
        <div className="w-100 d-flex justify-content-between align-items-center mb-3">
          <h5>
            {props.title} <span className="text-danger">*</span>{" "}
          </h5>
          <button onClick={props.onClick} className="btn bg-primary text-white">
            Add Row
          </button>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Type</th>
              <th>Value</th>
            </tr>
          </thead>

          <tbody>
            {props.data.map((row, index) => (
              <tr key={index}>
                <td>
                  <FormGroup>
                    <input
                      type="text"
                      name={`${props.name}[${index}].type`}
                      placeholder="Type"
                      className="form-control"
                      value={props.value[index].type}
                      onChange={(e) =>
                        props.onChange(index, "type", e.target.value)
                      }
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup>
                    <textarea
                      type="text"
                      name={`${props.name}[${index}].value`}
                      className="form-control"
                      placeholder="Value"
                      value={props.value[index].value}
                      onChange={(e) =>
                        props.onChange(index, "value", e.target.value)
                      }
                    />
                  </FormGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Col>
  );
}

export default AddRowComponent;
