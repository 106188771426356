import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import { Link } from "react-router-dom";
import { BaseUrl, token } from "../../utils/Token";
import EditModal from "./EditModal";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

function SliderList() {
  const [brand, setBrand] = useState([]);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [selectedId, setSelectedId] = useState("");
  const [open, setOpen] = useState(false);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/slider`, {
        headers,
      });
      setBrand(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {
    setSelectedId(id);
    handleShow();
  };
  const columns = [
    { field: "_id", headerName: "ID", width: 400 },

    {
      field: "image",
      headerName: "image",
      width: 300,
      renderCell: (params) => {
        const { value } = params;
        return (
          <img
            src={`http://62.72.30.137:8000/${value}`}
            className="img-fluid tableimgmui"
          ></img>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link>
            <IconButton
              style={{ color: "black" }}
              aria-label="edit"
              onClick={() => handleEditClick(id)}
            // onClick={() => handleShow(id)}
            >
              <EditIcon />
            </IconButton>
          </Link>
        );
      },
    },

    {
      field: "delete",
      headerName: "Delete",
      width: 300,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link>
            <IconButton aria-label="edit" onClick={() => handledeleteClick(id)}>
              <i class="fa-sharp fa-solid fa-trash text-dark"></i>
            </IconButton>
          </Link>
        );
      },
    },
  ];

  const handleFormSubmit = (event) => {
    setOpen(true);
    event.preventDefault();

    const formData = new FormData();
    formData.append("_id", selectedId);

    formData.append("file", file);

    axios
      .put(`${BaseUrl}/slider`, formData, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.status == "OK") {
          toast.success("Data Updated  Successfully");
          setOpen(false);
          window.location.reload(true);
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        toast.error("Data Not Updated ");
        setOpen(false);
      });
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile); // Store the uploaded file directly
  };

  const handledeleteClick = async (id) => {
    setOpen(true);
    let data = JSON.stringify({
      _id: id,
      deleted_at: "D",
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: "http://62.72.30.137:8000/slider",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.status == "OK") {
          toast.success("SuccessFully Deleted");
          setOpen(false);
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Data Not Deleted");
        setOpen(false);
      });
  };

  return (
    <>
      <Loader open={open} />
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  box">
                <h4 className="px-3 py-3">Slider List</h4>
                <div className="">
                  <DataGrid
                    rows={brand}
                    columns={columns}
                    getRowId={getRowId}
                    initialState={{
                      ...brand.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  // checkboxSelection
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <EditModal
        show={show}
        close={handleClose}
        onSubmit={handleFormSubmit}
        title="Update Img"
        type="file"
        name="image"
        onChange={handleFileUpload}
      />
    </>
  );
}

export default adminLayout(SliderList);
