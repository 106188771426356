import React from 'react'
import adminLayout from '../hoc/adminLayout'

function DashboardPage() {
  return (
    <>
      Dashboard
    </>
  )
}

export default adminLayout(DashboardPage)